import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Card,
  CardMedia,
  CardContent,
} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import logo from "./images/Newtologo.png";
import top from "./images/top.png";
import vector from "./images/Vector.png";
import down from "./images/down.png";
import staffing from "./images/staffing.png";
import recruiting from "./images/recruiting.png";
import puzzle from "./images/puzzle.png";

const App: React.FC = () => {
  const sections = [
    {
      title: "EMPLOYEE ENGAGEMENT",
      image: staffing,
      height: "30px",
    },
    {
      title: "HR OPERATIONS",
      image: recruiting,
      height: "40px",
    },
    {
      title: "Payroll Services",
      image: puzzle,
      height: "20px",
    },
  ];

  const benefits = [
    {
      title: "Competitive Cost",
      description:
        "We will work with you to increase service quality while achieving a significant reduction in its costs.",
    },
    {
      title: "Solution Oriented",
      description:
        "We meet client needs through an expert team, efficient processes, and progress reporting, achieving optimal solutions and performance measurement.",
    },
    {
      title: "Relationship",
      description:
        "We embody Respect, Accountability, Creativity, Responsibility, and Collaboration – valuing our clients' spirit, elevating expectations through our preferred supplier program.",
    },
    {
      title: "Committed Offering",
      description:
        "Our tenured professional staff will position us to deliver quality results. We are committed to providing the best resources available to you.",
    },
    {
      title: "Diverse Team",
      description:
        "The team we propose come from varied backgrounds and expertise in their respective industries such as Engineering, IT, ITES, Banking, Finance, Analyst, Startup, etc.",
    },
  ];

  const technologies = [
    "SAP & ERP",
    "Engineering",
    "Front-end Technologies",
    "Compliance services",
  ];

  return (
    <>
      {/* Navigation */}
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar
          sx={{
            justifyContent: "space-between",
            paddingX: { xs: 2, md: 4 },
            height: { xs: "60px", md: "80px" },
          }}
        >
          <Box
            component="img"
            src={logo}
            alt="Logo"
            sx={{ width: { xs: "40px", md: "70px" } }}
          />
          <Box sx={{ display: "flex", gap: 3 }}>
            <Button color="inherit" href="#about">
              About Us
            </Button>
            <Button color="inherit" href="#join">
              Join Us
            </Button>
          </Box>
        </Toolbar>
      </AppBar>

      <Box
        sx={{
          width: "100%",
          backgroundImage: `url(${vector})`,
          backgroundSize: "inherit",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingY: { xs: 4, md: 8 },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: { xs: "90%", md: "80%" },
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Box
              sx={{
                flex: 1,
                paddingRight: { md: 4 },
                marginBottom: { xs: 4, md: 0 },
              }}
            >
              <Typography
                variant="h4"
                gutterBottom
                sx={{ fontFamily: "Red Hat Display" }}
              >
                - Industry-focused resource consultancy
              </Typography>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: "2rem", md: "4rem" },
                  lineHeight: 1.2,
                  fontFamily: "Red Hat Display",
                }}
              >
                Your Industry
                <br /> Resource Navigator
              </Typography>
              <Typography
                variant="h6"
                sx={{ marginTop: 2, lineHeight: 1.6, maxWidth: "700px" }}
              >
                We create custom resource strategies, optimizing management for
                smooth problem-solving and untapped potential. As your dedicated
                partner, we understand objectives, analyze markets, and design
                effective, personalized approaches for exceptional outcomes.
              </Typography>
            </Box>
            <Box
              component="img"
              src={top}
              alt="Top Section Image"
              sx={{ width: { xs: "80%", md: "20%" }, height: "auto" }}
            />
          </Box>
        </Box>

        {/* Benefits Section */}
        <Box sx={{ paddingY: 8 }}>
          <Container maxWidth="lg">
            <Typography
              variant="h4"
              align="center"
              sx={{ fontWeight: "bold", marginBottom: 6 }}
            >
              Benefits Of Working With Us
            </Typography>
            <Grid container spacing={4} padding={2}>
              {benefits.map((benefit, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Box
                    sx={{
                      borderRadius: "20px",
                      padding: 4,
                      textAlign: "center",
                      backgroundColor: "#F0F0F0",
                      border: "2px solid white",
                      boxShadow:
                        "0px 4px 10px rgba(0, 0, 0, 0.1), 0px 6px 12px rgba(0, 0, 0, 0.05)",
                      transition: "transform 0.3s",
                      "&:hover": { transform: "scale(1.05)" },
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{ fontWeight: "bolder" }}
                      gutterBottom
                    >
                      {benefit.title}
                    </Typography>
                    <Typography variant="body2">
                      {benefit.description}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
      </Box>

      {/* Technology */}

      <Box
        id="about"
        sx={{
          width: "100%",
          backgroundColor: "#E3E3E3",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingY: { xs: 4, md: 8 },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: { xs: "90%", md: "80%" },
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Box
              sx={{
                flex: 1,
                paddingRight: { md: 4 },
                marginBottom: { xs: 4, md: 0 },
              }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: "2rem", md: "4rem" },
                  lineHeight: 1.2,
                  fontFamily: "Red Hat Display",
                }}
              >
                Technology
              </Typography>
              <Typography
                variant="h5"
                sx={{ marginTop: 3, lineHeight: 1.6, maxWidth: "700px" }}
                component="div"
              >
                NewTo is your one-stop destination for seamlessly integrating
                experience, technology, and processes which forms the base of
                any digital platform. Our experts analyze, strategize, and
                empower your core operations, unlocking digital potential across
                industries. We specialize in providing a diverse range of
                cutting-edge solutions tailored to various industries across
                sectors. Our expertise seamlessly connects technology to
                mobility, analytics, technological support, web innovation,
                process automation, and cloud solutions. Some of the
                Technologies
                <Typography
                  component="span"
                  sx={{ display: "block", marginTop: 1 }}
                >
                  we focus on are:
                </Typography>
              </Typography>
              <List
                sx={{
                  textAlign: "start",
                  lineHeight: "1.45",
                  fontSize: "18px",
                  fontWeight: 100,
                  marginBottom: "11%",
                }}
              >
                {technologies.map((tech, index) => (
                  <ListItem key={index} disablePadding>
                    <ListItemIcon sx={{ minWidth: "unset", marginRight: 1 }}>
                      <FiberManualRecordIcon
                        sx={{ fontSize: "8px", color: "black" }}
                      />
                    </ListItemIcon>
                    <ListItemText primary={tech} />
                  </ListItem>
                ))}
              </List>
            </Box>
            <Box
              component="img"
              src={down}
              alt="Down Section Image"
              sx={{ width: { xs: "80%", md: "20%" }, height: "auto" }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            backgroundImage: `url(${vector})`,
            backgroundSize: "inherit",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        ></Box>
      </Box>

      {/* Consultancy */}

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingY: { xs: 4, md: 8 },
          backgroundColor: "#ECECEC",
          backgroundImage: `url(${vector})`,
          backgroundSize: "inherit",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: { xs: "90%", md: "80%" },
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Box
            sx={{
              flex: 1,
              paddingRight: { md: 4 },
              marginBottom: { xs: 4, md: 0 },
            }}
          >
            <Typography
              variant="h4"
              gutterBottom
              sx={{ fontFamily: "Red Hat Display", marginBottom: 5 }}
            >
              -- Industry-focused resource consultancy
            </Typography>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: { xs: "2rem", md: "4rem" },
                lineHeight: 1.2,
                fontFamily: "Red Hat Display",
              }}
            >
              Consultancy
            </Typography>
            <Typography
              variant="h5"
              sx={{
                marginTop: 3,
                lineHeight: 1.6,
                maxWidth: "700px",
                fontSize: "24px",
              }}
              component="div"
            >
              Our comprehensive services cover every aspect of your HR
              operations, from seamless payroll management aligned with
              <Typography
                variant="h5"
                component="span"
                sx={{ display: "block", fontSize: "24px" }}
              >
                statutory compliance and audit requirements,
              </Typography>
              <Typography
                variant="h5"
                component="span"
                sx={{ display: "block", fontSize: "24px" }}
              >
                to efficient onboarding-to-exit processes.
              </Typography>
              <Typography
                variant="h5"
                component="span"
                sx={{ display: "block", fontSize: "24px" }}
              >
                We place a strong emphasis on fostering employee engagement
                through effective communication and recognition programs,
                creating a culture of appreciation and accolades.
              </Typography>
            </Typography>
          </Box>
          <Box sx={{ padding: 4 }}>
            <Grid container spacing={4} justifyContent="center">
              {sections.map((section, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card
                    sx={{
                      backgroundColor: "#F0E8E4",
                      boxShadow:
                        "0px 4px 10px rgba(50, 50, 93, 0.25), 0px 6px 12px rgba(0, 0, 0, 0.15)",
                      transition: "transform 0.3s",
                      "&:hover": { transform: "scale(1.05)" },
                      borderRadius: 8,
                      border: "2px solid white",
                    }}
                  >
                    <Box sx={{ height: section.height }} />
                    <CardMedia
                      component="img"
                      height="180"
                      image={section.image}
                      alt={section.title}
                      sx={{ objectFit: "contain", padding: 2 }}
                    />
                    <CardContent>
                      <Typography
                        variant="h6"
                        align="center"
                        sx={{ fontWeight: "bold" }}
                      >
                        {section.title}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Box>

      {/* Footer Section */}
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
          backgroundColor: "#ECECEC",
          paddingY: "5%",
          paddingX: "10%",
          textAlign: "center",
        }}
        id="join"
      >
        <Typography variant="h4" sx={{ fontWeight: "bold", marginBottom: 2 }}>
          NewTo Solutions Consulting Private Limited
        </Typography>
        <Box>
          <Typography variant="h6" component="span" sx={{ fontWeight: "bold" }}>
            CONTACT
            <Typography
              variant="body1"
              component="span"
              sx={{ display: "block", marginTop: 2 }}
            >
              Email: hrpayroll@nschr.com
            </Typography>
            <Typography
              variant="body1"
              component="span"
              sx={{ display: "block" }}
            >
              Phone: +91 9663641900
            </Typography>
          </Typography>
        </Box>
        <Box sx={{ marginTop: { xs: 2 } }}>
          <form>
            <input
              type="email"
              placeholder="Email"
              required
              size={40}
              style={{
                padding: "0.75rem",
                borderRadius: "25px",
                border: "1px solid #ccc",
                marginRight: 5,
              }}
            />
            <Button
              type="submit"
              variant="contained"
              sx={{
                backgroundColor: "#000",
                color: "#fff",
                borderRadius: "25px",
                "&:hover": { backgroundColor: "#333" },
              }}
            >
              Submit
            </Button>
          </form>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: "#ECECEC",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          paddingY: 2,
          paddingX: 5,
          borderTop: "1px solid #9A9A9A",
        }}
      >
        <Typography variant="caption">Privacy & Policy</Typography>
        <Typography variant="caption">@2023 Newto</Typography>
      </Box>
    </>
  );
};

export default App;
